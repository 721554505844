<template>
    <!-- My Details -->
    <vx-card>
        <!-- Edit Profile Detail -->
        <h3 class="font-lg mb-4">Account Details</h3>
        <template v-if="edit" title="Edit Profile Detail">
          <ProfileEdit :userData="userDetail" @cancelEdit="cancelEdit" />
        </template>

        <!-- Profile Detail -->
        <template v-else title="Account Details">
          <ProfileDetail />
        </template>

        <div class="w-full lg:w-4/12 flex flex-col lg:flex-row items-center lg:ml-auto space-y-2 lg:space-y-0 lg:space-x-2 mt-6">
          <ProfileChangePassword v-if="!edit" />
          <vs-button
            v-if="edit"
            icon-pack="feather"
            icon="icon-x"
            color="danger"
            type="filled"
            @click="cancelEdit()"
            class="w-full lg:flex-1"
            >Cancel Edit</vs-button
          >
          <vs-button
            v-else
            icon-pack="feather"
            icon="icon-edit"
            color="primary"
            type="filled"
            class="w-full lg:flex-1"
            @click="editProfile()"
            >Edit Profile</vs-button
          >
        </div>
    </vx-card>
</template>

<script>
import ProfileDetail from "@/views/components/profile/ProfileDetail";
import ProfileEdit from "@/views/components/profile/ProfileEdit";
import ProfileChangePassword from "@/views/components/profileChangePassword";

export default {
  components: {
    ProfileDetail,
    ProfileEdit,
    ProfileChangePassword,
  },
  data() {
    return {
      edit: false,
      userDetail: {},
    };
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  methods: {
    editProfile() {
      this.userDetail = this.$store.state.AppActiveUser;
      this.edit = true;
    },
    cancelEdit() {
      this.userDetail = this.$store.state.AppActiveUser;
      this.edit = false;
    },
  },
  created() {
    this.userDetail = this.$store.state.AppActiveUser;
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/pages/profile.scss";
</style>
